import Vue from 'vue'
import Vuex from 'vuex'
import { IUtilisateur } from './types'
import { stringFromDate } from '../plugins/variables'

Vue.use(Vuex)
const sites = require('@/assets/sites.json')
const chantiers = require('@/assets/chantiers.json')
const debug = process.env.NODE_ENV === 'development'
const jour = stringFromDate(new Date())
const isCordovaApp = typeof (window as any).cordova !== 'undefined'

export default new Vuex.Store({
  state: {
    version: '04',
    ajaxurl: debug && !isCordovaApp ? 'http://localhost/oxytec/chantiersv2/php/' : 'https://chantiers.oxytec.ovh/php/',
    debug: debug,
    jour: jour,
    salaries: [] as IUtilisateur[],
    machines: [],
    isCordovaApp: isCordovaApp,
    site: 'BELVAL',
    site_id: 1,
    sites: sites,
    chantiers: chantiers as { ch_id: number; ch_nom: string }[],
    chantier: '',
    ch_id: 0,
    debutPoste: false, // si poste en cours
    dateDebutPoste: '', // si poste en cours
    authentifier: false,
    poste: ''
  },
  mutations: {
    majJour(state, ndate) {
      state.jour = ndate
    },
    actualiserJour(state) {
      state.jour = stringFromDate(new Date())
    },
    majMachines(state, machines) {
      state.machines = machines.slice()
    },
    majSalaries(state, salaries) {
      state.salaries = salaries.slice()
    },
    majChantiers(state, chantiers) {
      state.chantiers = chantiers.slice()
    },
    majDebutPoste(state, payload) {
      state.debutPoste = payload
    },
    majDateDebutPoste(state, payload) {
      state.dateDebutPoste = payload
    },
    majSite(state, siteNom) {
      state.site = siteNom
      for (const s of sites) {
        if (s.site == siteNom) {
          state.site_id = s.site_id
          break
        }
      }
      localStorage.setItem('oxytec_site', siteNom)
    },
    majChantier(state, chantierNom) {
      state.chantier = chantierNom
      for (const s of chantiers) {
        if (s.ch_nom == chantierNom) {
          state.ch_id = s.ch_id
          break
        }
      }
      localStorage.setItem('oxytec_chantier', chantierNom)
    },
    majAuthentification(state, authentifier) {
      state.authentifier = authentifier
    },
    majPoste(state, poste) {
      state.poste = poste
    }
  }
})
