
import Vuetify from 'vuetify'
import Vue from 'vue'
const isCordovaApp = typeof (window as any).cordova !== 'undefined'

export default Vue.extend({
  name: 'About',
  data() {
    return {
      appliversion: '2023.004',
      vuetifyVersion: Vuetify.version,
      vueVersion: Vue.version,
      utilisateur: this.$store.state.site + ' ' + this.$store.state.chantier,
      url: this.$store.state.ajaxurl,
      isCordovaApp: isCordovaApp
    }
  },
  methods: {
    fermerAbout() {
      this.$emit('fermer')
    }
  }
})
