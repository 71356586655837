import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/Layout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Layout,
    children: [
      {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Login.vue')
      },
      {
        path: '/fichetop',
        name: 'top',
        component: () => import('@/views/FicheTOP.vue')
      },
      {
        path: '/nw',
        component: () => import('@/views/Nettoyage.vue')
      },
      {
        path: '/poste',
        name: 'Poste',
        component: () => import('@/views/Poste.vue')
      },
      {
        path: '/journal',
        name: 'Journal',
        component: () => import('@/views/Journal.vue')
      },
      {
        path: '/transferts',
        name: 'Transferts',
        component: () => import('@/views/Transferts.vue')
      },

      {
        path: '/checklist',
        component: () => import('@/views/Checklist.vue')
      },
      {
        path: '/anomalie',
        component: () => import('@/views/Anomalie.vue')
      },
      {
        path: '/maintenance',
        component: () => import('@/views/Maintenance.vue')
      },
      {
        path: '/importer',
        name: 'Importations',
        component: () => import('@/components/ImportTables.vue')
      },
      {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '@/components/About.vue')
      }
    ]
  }
]

const router = new VueRouter({
  routes
})
const isCordovaApp = typeof (window as any).cordova !== 'undefined'

export default router
