export function FRFromISO(date: string): string {
  const dte = date.split('-')
  if (dte) return dte[2] + '/' + dte[1] + '/' + dte[0]
  else return ''
}
export function stringFromDate(dte: Date): string {
  if (dte === null) return ''
  if (dte === undefined) return ''
  const y = dte.getFullYear()
  const mo = dte.getMonth() + 1
  const d = dte.getDate()
  const mmo = (mo < 10 ? '0' : '') + mo
  const dd = (d < 10 ? '0' : '') + d
  return `${y}-${mmo}-${dd}`
}
export function stringFromDatetime(dte: Date): string {
  if (dte === null) return ''
  if (dte === undefined) return ''
  const y = dte.getFullYear()
  const mo = dte.getMonth() + 1
  const d = dte.getDate()
  const mmo = (mo < 10 ? '0' : '') + mo
  const dd = (d < 10 ? '0' : '') + d

  const h = dte.getHours()
  const mn = dte.getMinutes()
  const hh = (h < 10 ? '0' : '') + h
  const mmn = (mn < 10 ? '0' : '') + mn

  return `${y}-${mmo}-${dd} ${hh}:${mmn}`
}
export function datetimeFromString(stdatetime: string): Date | null {
  if (stdatetime == null) return new Date()
  const regex1 = /(\d*)-(\d*)-(\d*)\s+(\d*):(\d*)/g
  const dte = regex1.exec(stdatetime)
  if (dte) {
    const date = new Date(parseInt(dte[1]), parseInt(dte[2]) - 1, parseInt(dte[3]), parseInt(dte[4]), parseInt(dte[5]))
    return date
  }
  return null
}
export function dateFromString(stdate: string /** yyyy-mm-dd */): Date {
  if (stdate == null) return new Date()
  const [year, month, day] = stdate.split('-')
  const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), 12)
  return date
}
export function cleControle(): string {
  // la date est codée en base 31
  const dte = new Date()
  dte.setHours(12)
  const n = ((dte.getMonth() + 1) * 100 + dte.getDate()) * 10000 + dte.getFullYear()
  const cle = n.toString(31)
  return cle
}

import { openDB, IDBPDatabase } from 'idb'

export function dbOxytec(): Promise<IDBPDatabase<unknown>> {
  const db = openDB('chantiersOxytec', 3, {
    upgrade(db, oldversion) {
      if (!db.objectStoreNames.contains('oxytec_rapports')) {
        const objectStore = db.createObjectStore('oxytec_rapports', { keyPath: 'poste_id' }) // ex poste_id='2020-07-01AP' (jour+poste)
        objectStore.createIndex('date', 'rpt_date')
      }
      if (!db.objectStoreNames.contains('oxytec_wagons')) {
        const wagons = db.createObjectStore('oxytec_wagons', { keyPath: 'nt_id' }) // create it
        wagons.createIndex('date', 'nt_date')
      }
      if (!db.objectStoreNames.contains('oxytec_salaries')) {
        db.createObjectStore('oxytec_salaries', { keyPath: 'ut_id' }) //
      }
      if (!db.objectStoreNames.contains('oxytec_pesees')) {
        db.createObjectStore('oxytec_pesees', { keyPath: 'tk_id' }).createIndex('date', 'tk_date')
      }
      if (!db.objectStoreNames.contains('oxytec_fichetop')) {
        const objectStore = db.createObjectStore('oxytec_fichetop', { keyPath: 'top_pkey' }) // top_pkey = site-id+date+poste+code
        objectStore.createIndex('date', 'top_date') //
        objectStore.createIndex('envoyer', 'envoyer_serveur', { unique: false }) //char(1) O/N. true si à envoyer au serveur(non sauvegardé)
      }
      if (!db.objectStoreNames.contains('oxytec_pointarret')) {
        const objectStore = db.createObjectStore('oxytec_pointarret', { keyPath: 'pta_pkey' }) // pta_pkey = date+site_id+'-numligne'
        objectStore.createIndex('date', 'pta_date')
        objectStore.createIndex('envoyer', 'envoyer_serveur', { unique: false }) //char(1) O/N. true si à envoyer au serveur(non sauvegardé)
      }
      if (!db.objectStoreNames.contains('oxytec_machines')) {
        db.createObjectStore('oxytec_machines', { keyPath: 'mac_id' })
      }
      if (!db.objectStoreNames.contains('oxytec_checklistes')) {
        const objectStore = db.createObjectStore('oxytec_checklistes', { keyPath: 'ck_id' }) // sdate+'-'+poste+'-'+mac_id+'-'+site_id+'-'+ch_id
        objectStore.createIndex('date', 'ck_date')
        objectStore.createIndex('envoyer', 'envoyer_serveur', { unique: false }) //char(1) O/N. true si à envoyer au serveur(non sauvegardé)
      }
      if (!db.objectStoreNames.contains('oxytec_anomalies')) {
        const objectStore = db.createObjectStore('oxytec_anomalies', { keyPath: 'an_id' })
        objectStore.createIndex('date', 'an_date')
        objectStore.createIndex('envoyer', 'envoyer_serveur', { unique: false }) //char(1) O/N. true si à envoyer au serveur(non sauvegardé)
      }
      if (!db.objectStoreNames.contains('oxytec_maintenance')) {
        const objectStore = db.createObjectStore('oxytec_maintenance', { keyPath: 'mt_id' })
        objectStore.createIndex('date', 'mt_date')
        objectStore.createIndex('envoyer', 'envoyer_serveur', { unique: false }) //char(1) O/N. true si à envoyer au serveur(non sauvegardé)
      }
      if (!db.objectStoreNames.contains('oxytec_chantiers')) {
        db.createObjectStore('oxytec_chantiers', { keyPath: 'ch_id' })
      }
    }
  })
  return db
}

export const variables = {
  authentifie: false
}
