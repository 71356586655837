import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{staticStyle:{"height":"100%"}},[_c(VToolbar,{attrs:{"dark":"","color":"teal lighten-2"}},[_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.fermerAbout()}}},[_c(VIcon,[_vm._v("mdi-close")])],1),_c(VToolbarTitle,[_vm._v("À propos")]),_c(VSpacer)],1),_c('div',{staticClass:"pa-4"},[_c('p',[_c('a',{attrs:{"href":"https://chantiers.oxytec.ovh/app/chantiers.apk"}},[_vm._v("Télécharger la dernière version")])]),_c('p',[_vm._v(" Version "),_c('b',[_vm._v(_vm._s(_vm.appliversion))])]),_c('p',[_vm._v(" Vuejs "),_c('b',[_vm._v(_vm._s(_vm.vueVersion))])]),_c('p',[_vm._v(" Vuetify "),_c('b',[_vm._v(_vm._s(_vm.vuetifyVersion))])]),_c('p',[_vm._v(" Utilisateur "),_c('b',[_vm._v(_vm._s(_vm.utilisateur))])]),_c('p',[_vm._v("Développement "),_c('b',[_vm._v("Marc Duren")])]),_c('p',[_c(VIcon,[_vm._v("mdi-mail")]),_c('a',{attrs:{"mailto":"contact.infa2d@gmail.com"}},[_vm._v("marc.duren@mdsp.fr")]),_vm._v(" "),_c(VIcon,[_vm._v("mdi-cellphone")]),_vm._v(" +33 6 05 11 20 09")],1),_c('p',[_vm._v(" url "),_c('b',[_vm._v(_vm._s(_vm.url))])]),_c('p',[_vm._v(" Cordova "),_c('b',[_vm._v(_vm._s(_vm.isCordovaApp))])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }