
import Vue from 'vue'
import confirm from '@/components/Confirm.vue'
import ImportTables from './components/ImportTables.vue'
import APropos from /* webpackChunkName: "about" */ './components/About.vue'
import { dbOxytec } from './plugins/variables'

interface IConfirmer extends Vue {
  openDialogConfirm(title: string, message: string, options: object): Promise<boolean>
}
declare module 'vue/types/vue' {
  // 3. Déclarez l'augmentation pour Vue
  interface Vue {
    $Confirmer: (title: string, message: string, options: object) => Promise<boolean>
  }
}

export default Vue.extend({
  name: 'App',
  components: { confirm, ImportTables, APropos },
  data() {
    return {
      unsubscribe: null as any,
      site: this.$store.state.site,
      authentifier: this.$store.state.authentifier,
      chantier: this.$store.state.chantier as string,
      DlgImport: false,
      DlgAPropos: false,
      DlgDeconnexion: false,
      codeDeconnexion: ''
    }
  },
  async created() {
    this.authentifier = localStorage.getItem('oxytec_authentifier') == 'true'
    if (this.authentifier) {
      if (this.$route.path != '/poste') this.$router.push('/poste')
    } else {
      if (this.$route.path != '/login') this.$router.push('/login')
    }
    this.$store.commit('majAuthentification', this.authentifier)
    this.site = localStorage.getItem('oxytec_site')
    this.$store.commit('majSite', this.site)
    this.chantier = localStorage.getItem('oxytec_chantier') || ''
    this.$store.commit('majChantier', this.chantier)
    this.$store.commit('majDebutPoste', JSON.parse(localStorage.getItem('oxytec_debutposte') ?? 'false'))
    this.$store.commit('majDateDebutPoste', localStorage.getItem('oxytec_datedebutposte' ?? ''))
    const db = await dbOxytec()
    let chantiers = await db.getAll('oxytec_chantiers')
    if (chantiers) this.$store.commit('majChantiers', chantiers)
    db.close()

    this.unsubscribe = this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case 'majSite':
          this.site = mutation.payload
          break
        case 'majChantier':
          this.chantier = mutation.payload
          break
        case 'majAuthentification':
          this.authentifier = mutation.payload
          if (!this.authentifier && this.$route.path != '/login') this.$router.push('/login')
          else if (this.authentifier && this.$route.path != '/poste') this.$router.push('/poste')
          break
      }
    })
  },
  beforeDestroy() {
    this.unsubscribe()
  },
  mounted() {
    this.$root.$Confirmer = (title: string, message: string, options: object) => (this.$refs.confirm as IConfirmer).openDialogConfirm(title, message, options)
  },
  methods: {
    async deconnexion() {
      if (this.codeDeconnexion == localStorage.getItem('oxytec_mdp')) {
        this.DlgDeconnexion = false
        const db = await dbOxytec()
        await db.clear('oxytec_anomalies')
        await db.clear('oxytec_rapports')
        await db.clear('oxytec_wagons')
        await db.clear('oxytec_pesees')
        await db.clear('oxytec_fichetop')
        await db.clear('oxytec_pointarret')
        await db.clear('oxytec_checklistes')
        await db.clear('oxytec_maintenance')
        db.close()
        this.codeDeconnexion = ''
        localStorage.setItem('oxytec_authentifier', 'false')
        this.authentifier = false
        this.$store.commit('majAuthentification', this.authentifier)
      }
    }
  }
})
