
import Vue from 'vue'
import axios from 'axios'
import { cleControle, dbOxytec, stringFromDate } from '../plugins/variables'

export default Vue.extend({
  name: 'Salariés',
  data() {
    return {
      logs: [] as string[],
      salaries: [] as any[],
      machines: [] as any[],
      chantiers: [] as { ch_id: number; ch_nom: string }[],
      colonnesSalaries: [
        { text: 'Nom', value: 'ut_nom' },
        { text: 'Prénom', value: 'ut_prenom' },
        { text: 'Chantier', value: 'ch_nom' }
      ],
      colonnesMachines: [
        { text: 'Catégorie', value: 'mac_categorie' },
        { text: 'Machine', value: 'mac_desi' }
      ],
      dateDernImport: ''
    }
  },
  methods: {
    async chargerTables() {
      const db = await dbOxytec()
      let chantiers = await db.getAll('oxytec_chantiers')
      for (let rep of chantiers) {
        this.chantiers.push({ ch_id: rep.ch_id, ch_nom: rep.ch_nom })
      }
      let response = await db.getAll('oxytec_salaries')
      for (let rep of response) {
        const j = this.chantiers.findIndex((c) => c.ch_id == rep.ch_id)
        const ch_nom = j > -1 ? this.chantiers[j].ch_nom : ''
        this.salaries.push({ ut_id: rep.ut_id, ut_nom: rep.ut_nom, ut_prenom: rep.ut_prenom, ch_id: rep.ch_id, ch_nom: ch_nom })
      }
      let machines: any = await db.getAll('oxytec_machines')
      machines.sort((a: any, b: any) => {
        if (a.mac_categorie == b.mac_categorie) {
          if (a.mac_desi < b.mac_desi) {
            return -1
          }
          return 1
        }
        if (a.mac_categorie < b.mac_categorie) {
          return -1
        }
        return 1
      })
      for (let mac of machines) {
        this.machines.push({ mac_id: mac.mac_id, mac_desi: mac.mac_desi, mac_categorie: mac.mac_categorie })
      }
      const dateImport = localStorage.getItem('oxytec_dateDernImport')
      if (dateImport) this.dateDernImport = dateImport.split('-')[2] + '/' + dateImport.split('-')[1] + '/' + dateImport.split('-')[0]
      db.close()
    },
    async majDB() {
      const db = await dbOxytec()
      const tbSalaries = db.transaction('oxytec_salaries', 'readwrite').store
      for (let sal of this.salaries) {
        tbSalaries.put(sal)
      }
      const tbMachines = db.transaction('oxytec_machines', 'readwrite').store
      for (let mac of this.machines) {
        tbMachines.put(mac)
      }
      const tbChantiers = db.transaction('oxytec_chantiers', 'readwrite').store
      for (let ch of this.chantiers) {
        tbChantiers.put(ch)
      }

      this.$store.commit('majMachines', this.machines)
      this.$store.commit('majSalaries', this.salaries)
      this.$store.commit('majChantiers', this.chantiers)
      let dateImport = stringFromDate(new Date())
      this.dateDernImport = dateImport.split('-')[2] + '/' + dateImport.split('-')[1] + '/' + dateImport.split('-')[0]
      localStorage.setItem('oxytec_dateDernImport', stringFromDate(new Date()))
      db.close()
    },
    importer() {
      var url = this.$store.state.ajaxurl + 'ajax_appli.php'
      axios
        .get(url, {
          params: {
            cle: cleControle(),
            action: 'TABLES'
          }
        })
        .then((response) => {
          if (response.data.salaries) {
            this.salaries = []
            for (let sal of response.data.salaries) {
              this.salaries.push(sal)
            }
          }
          if (response.data.machines) {
            this.machines = []
            for (let mac of response.data.machines) {
              this.machines.push(mac)
            }
          }
          if (response.data.chantiers) {
            this.chantiers = []
            for (let ch of response.data.chantiers) {
              this.chantiers.push(ch)
            }
          }
          this.majDB()
        })
    },
    fermerImport() {
      this.$router.push('/')
      this.$router.push('/poste')
      this.$emit('fermer')
    }
  },
  mounted() {
    this.chargerTables()
  }
})
