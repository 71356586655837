
import Vue from 'vue'
import { stringFromDate, dateFromString } from '../plugins/variables'

export default Vue.extend({
  name: 'Layout',
  data() {
    return {
      unsubscribe: null as any,
      iTab: '',
      menuDate: false,
      date: stringFromDate(new Date()),
      authentifier: this.$store.state.authentifier,
      accesPageNettoyageWagon: localStorage.getItem('oxytec_accesNettoyageWagon') == 'O'
    }
  },
  methods: {
    majDate() {
      this.$store.commit('majJour', this.date)
      ;(this.$refs.menu as any).save(this.date)
    },
    modifjour(nbj: number) {
      let dte = dateFromString(this.date)
      this.date = stringFromDate(new Date(dte.getFullYear(), dte.getMonth(), dte.getDate() + nbj, 12))
      this.majDate()
    }
  },
  created() {
    this.iTab = ''
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case 'majAuthentification':
          this.authentifier = this.$store.state.authentifier
          this.accesPageNettoyageWagon = localStorage.getItem('oxytec_accesNettoyageWagon') == 'O'
          break
      }
    })
  },
  beforeDestroy() {
    this.unsubscribe()
  }
})
